html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
}

button {
  font-size: 1.5rem;
}

.full-width {
  width: 100%;
}

#root {
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}

.header {
  grid-area: header;
  background-color: #203040;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.brand a {
  color: #ffffff;
  font-size: 2.5 rem;
  font-weight: bold;
  text-decoration: none;
}

.header-links a {
  color: #ffffff;
  text-decoration: none;
  padding: 1rem;
}

.header-links a:hover {
  color: #ff8000;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
  background-color: #203040;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

a:hover {
  color: #ff8000;
}

/* Home Screen */
.strategies {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.strategies li {
  list-style-type: none;
  padding: 0;
  flex: 0 1 34rem;
  margin: 1rem;
  height: 50rem;
  border-bottom: 0.1rem #c0c0c0 solid;
}

.strategy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.strategy-name {
  font-size: 2rem;
  font-weight: bold;
}

.strategy-currency {
  font-size: 1.2rem;
  color: #808080;
}

.strategy-budget {
  font-size: 2rem;
  font-weight: bold;
}

.strategy-image {
  max-width: 34rem;
  max-height: 34rem;
}

.strategy-balace {
  border-bottom: 0.1rem #c0c0c0 solid;
}

/* sidebar */
.brand button {
  font-size: 3rem;
  padding: 0.5rem;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  transition: all 0.5s;
  transform: translateX(-30rem);
  width: 30rem;
  background-color: #f0f0f0;
  height: 100%;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-close-button {
  border-radius: 50%;
  border: 0.1rem #000000 solid;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  font-size: 2rem;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
}

/* Strategy Details */
.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}

.details-image {
  flex: 2 1 60rem;
}

.details-image img {
  max-width: 60rem;
  widows: 100%;
}

.details-info {
  flex: 1 1 30rem;
}

.details-action {
  flex: 1 1 30rem;
}

.details-info ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}

.details-info li,
.details-action li {
  margin-bottom: 1rem;
}

.back-to-home {
  padding: 1rem;
}

.details-action {
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  background-color: #f0f0f0;
  padding: 1rem;
}

.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}

.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}

.button:hover {
  border: 0.1rem #408080 solid;
}

.button.primary {
  background-color: #f0c040;
}
.button.secondary{
  background-color: #f0f0f0;
}
.text-center{
  text-align: center;
}
/* Cart */

.cart {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  align-items: flex-start;
}

.cart-list {
  flex: 3 1 60rem;
}

.cart-list-container {
  padding: 0;
  list-style-type: none;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem #808080 solid;
}

.cart-list-container li img {
  max-width: 10rem;
  max-height: 10rem;
}

.cart-action {
  flex: 1 1 20rem;
  background-color: #f8f8f8;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  padding: 1rem;
}

.cart-image {
  flex: 1 1;
}

.cart-name {
  flex: 8 1;
}

.cart-balance {
  flex: 1 1;
  font-size: 2.5.rem;
}

/* forms */
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: .5rem;
  list-style-type: none;
}

.form-container li{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

input{
  padding: 1rem;
  border: 1rem;
  border-radius: .5rem;
}

/* Strategies */
.strategy-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content-margined{
  margin: 1rem;
}

.table{
  width: 100%;
}

th{
  text-align:left;
}

tbody>tr:nth-child(odd){
  background-color: #f0f0f0;
}

